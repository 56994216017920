import React from "react";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import { Container, Box } from "@chakra-ui/react";
import styles from "./Page.module.css";

export default function ({ children, ...props }) {
  return (
    <Box className={styles.Page} bg="gray.100" {...props}>
      <Navigation transparent darkImage />
      <Container maxW="container.lg" className={styles.Content}>
        {children}
      </Container>

      <Footer />
    </Box>
  );
}
